const StatMap = {
    AGENTS_AFTER_CONTACT_WORK:'int',
    AGENTS_AVAILABLE:'int',
    AGENTS_ERROR:'int',
    AGENTS_NON_PRODUCTIVE:'int',
    AGENTS_ON_CALL:'int',
    AGENTS_ON_CONTACT:'int',
    AGENTS_ONLINE:'int',
    AGENTS_STAFFED:'int',
    CONTACTS_IN_QUEUE:'int',
    CONTACTS_SCHEDULED:'int',
    OLDEST_CONTACT_AGE: 'time',
    SLOTS_ACTIVE:'int',
    SLOTS_AVAILABLE:'int',
    ABANDON_TIME: 'time',
    AFTER_CONTACT_WORK_TIME: 'time',
    API_CONTACTS_HANDLED:'int',
    CALLBACK_CONTACTS_HANDLED:'int',
    CONTACTS_ABANDONED:'int',
    CONTACTS_AGENT_HUNG_UP_FIRST:'int',
    CONTACTS_CONSULTED:'int',
    CONTACTS_HANDLED:'int',
    CONTACTS_HANDLED_INCOMING:'int',
    CONTACTS_HANDLED_OUTBOUND:'int',
    CONTACTS_HOLD_ABANDONS:'int',
    CONTACTS_MISSED:'int',
    CONTACTS_QUEUED:'int',
    CONTACTS_TRANSFERRED_IN:'int',
    CONTACTS_TRANSFERRED_IN_FROM_QUEUE:'int',
    CONTACTS_TRANSFERRED_OUT:'int',
    CONTACTS_TRANSFERRED_OUT_FROM_QUEUE:'int',
    HANDLE_TIME: 'time',
    HOLD_TIME: 'time',
    INTERACTION_AND_HOLD_TIME: 'time',
    INTERACTION_TIME: 'time',
    OCCUPANCY: 'percentage',
    QUEUE_ANSWER_TIME: 'time',
    QUEUED_TIME: 'time',
    SERVICE_LEVEL_120: 'percentage',
    SERVICE_LEVEL_30: 'percentage',
    SERVICE_LEVEL_20: 'percentage',
    SERVICE_LEVEL_60: 'percentage',
    CALLBACKS_WAITING: 'int',
    OLDEST_CALLBACK: 'callbacktime',
    AvgActiveDuration: 'time',
    AvgQueuedDuration: 'time',
    Forward: 'int',
    Inbound: 'int',
    InboundReply: 'int',
    Outbound: 'int',
    agentsAvailable: 'int',
    agentsInError: 'int',
    agentsInWrap: 'int',
    agentsStaffed: 'int',
    agentsUnavailable: 'int',
    agentsWorking: 'int',
    itemsBeingDelivered: 'int',
    itemsInQueue: 'int',
    oldestItem: 'time',
    ABANDONMENT_RATE: 'percentage',
    AVG_ABANDON_TIME: 'time',
    AVG_AFTER_CONTACT_WORK_TIME: 'time',
    AVG_CONTACT_DURATION: 'time',
    AVG_CONVERSATION_DURATION: 'time',
    AVG_GREETING_TIME_AGENT: 'time',
    AVG_HANDLE_TIME: 'time',
    AVG_HOLDS: 'int',
    AVG_HOLD_TIME: 'time',
    AVG_INTERACTION_AND_HOLD_TIME: 'time',
    AVG_INTERACTION_TIME: 'time',
    AVG_INTERRUPTIONS_AGENT: 'int',
    AVG_INTERRUPTION_TIME_AGENT: 'time',
    AVG_NON_TALK_TIME: 'time',
    AVG_QUEUE_ANSWER_TIME: 'time',
    AVG_RESOLUTION_TIME: 'time',
    AVG_TALK_TIME: 'time',
    AVG_TALK_TIME_AGENT: 'time',
    AVG_TALK_TIME_CUSTOMER: 'time',
    CONTACTS_CREATED_QUEUE_TRANSFER: 'int',
    CONTACTS_HANDLED_AGENT_DISCONNECT: 'int',
    CONTACTS_HANDLED_API: 'int',
    CONTACTS_HANDLED_CALLBACK: 'int',
    MAX_QUEUED_TIME: 'time'

};

export default StatMap;