import { useState, useEffect } from 'react';
import Wallbaord from './Components/Wallboard/Wallboard';
import LoginPage from './Components/Common/LoginPage';
import axios from 'axios';
import { DynamoDBClient } from '@aws-sdk/client-dynamodb';
import { fromCognitoIdentityPool } from "@aws-sdk/credential-providers";

function App() {
    
    
    
    
    const [boardList, setBoardList] = useState(null);
    const [board, setBoard] = useState('f954f73c-9322-459a-bec8-9a225333afdf');
    const [queues, setQueues] = useState(null);
    const [ccxQueues, setCcxQueues] = useState(null);
    const [editorMode, setEditorMode] = useState(false);
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const [dynamoClient, setDynamoClient] = useState(null);
    const [authInProgress, setAuthInProgress] = useState(false);
    const [aliasError, setAliasError] = useState(false);
    
    const [idToken, setIdToken] = useState(null);
    const [accessToken, setAccessToken] = useState(null);
    const [refreshToken, setRefreshToken] = useState('');
    const [expiresIn, setExpiresIn] = useState(0);
    const [admin, setAdmin] = useState(false);
    // const [tokenType, setTokenType] = useState('');

    
    
    const aliasUrl = 'https://l1vwabn93l.execute-api.ap-southeast-2.amazonaws.com/default/getCompanyAlias';
    const [serverState, setServerState] = useState({
            cognitoDomain: null,
            appClientId: null,
            redirectUri: null,
            appClientSecret: null,
            connectivityDetailsUrl: null,
            region: null,
            connectionUrl: null,
            identityPoolId: null,
            userPoolId: null,
            currentTable: null,
            historicTable: null,
            callbackTable: null,
            definitionTable: null,
            queueArnTable: null,
            featureFlags: {
                symbeeStats: false
            },
            boardGUID: null,
            alias: null
        });
        
    const getUserAttributes = async (token) => {
            try {
                // Get payload
                const payload = token.split('.')[1];
                // Base64 decode
                const base64 = payload.replace(/-/g, '+').replace(/_/g, '/');
                // Parse JSON 
                const jsonPayload = decodeURIComponent(atob(base64).split('').map(function(c) {
                    return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
                }).join(''));
        
                const tokenJson = JSON.parse(jsonPayload);
                if(tokenJson?.profile === 'admin'){
                    setAdmin(true);
                }
            } catch (e) {
                console.error(e);
                return null;
            }
          
        };

    
    const fetchToken = async (code, boardGUID, cognitoDomain, appClientId, appClientSecret, redirectUri, connectionUrl, region, alias) => {
        //fetch company instance spefic data
        console.log("fetching company data", "alias is:", alias)
        const companyResult = await axios.get(connectionUrl);
        const companyData = companyResult.data;
        setServerState({
            cognitoDomain: cognitoDomain, 
            appClientId: appClientId, 
            redirectUri: redirectUri, 
            appClientSecret: appClientSecret, 
            region: region, 
            connectionUrl: connectionUrl,
            userPoolId: companyData.userPoolId,
            identityPoolId: companyData.identityPoolId,
            currentTable: companyData.currentTable,
            historicTable: companyData.historicTable,
            historicTableV2: companyData.historicTableV2,
            callbackTable: companyData.callbackTable,
            definitionTable: companyData.definitionTable,
            queueArnTable: companyData.queueArnTable,
            ccxQueueIdTable: companyData.ccxQueueIdTable,
            ccxCurrentTable: companyData.ccxCurrentTable,
            ccxHistoricTable: companyData.ccxHistoricTable,
            featureFlags: {
              symbeeStats: companyData?.featureFlags?.symbeeStats == true || companyData?.featureFlags?.symbeeStats == 'true' ? true : false  
            },
            boardGUID: boardGUID,
            alias: alias
        });
        console.log(companyData);
        console.log('Symbee stats active? - ', companyData?.featureFlags?.symbeeStats == true  || companyData?.featureFlags?.symbeeStats == 'true' ? true : false );

        const base64EncodedCredentials = btoa(`${appClientId}:${appClientSecret}`);
        const config = {
                headers: {
                  'Content-Type': 'application/x-www-form-urlencoded',
                  Authorization: `Basic ${base64EncodedCredentials}`,
                },
              };
        
        const requestData = new URLSearchParams();
          requestData.append('grant_type', 'authorization_code');
          requestData.append('code', code);
          requestData.append('redirect_uri', redirectUri);
          
        try {
          const response = await axios.post(`${cognitoDomain}/oauth2/token`, requestData, config);
          const data = response.data;
          await getUserAttributes(data.id_token);
          //store token at browser
          localStorage.setItem(alias, JSON.stringify(data));
          setIsAuthenticated(true);
          setAuthInProgress(false);
          setIdToken(data.id_token);
          setAccessToken(data.access_token);
          setRefreshToken(data.refresh_token);
          setExpiresIn(data.expires_in);
        //   console.log('Auth issue data: ', data);
        //   setTokenType(data.token_type);
        } catch (error) {
          console.log(error);
        }
    
    };

    const signIn = async (alias) => {
        setAuthInProgress(true);
        const envResult = await axios.get(`${aliasUrl}?companyAlias=${alias}`);
        envResult.data.alias = alias;
        const searchParams = new URLSearchParams(window.location.search);
        let boardGUID = searchParams.get('GUID');
        envResult.data.boardGUID = boardGUID;
        envResult.data.redirectUri = `https://mywallboard.cornerstonecx.co.nz/`;
     
        
        const envData = btoa(JSON.stringify(envResult.data));
        console.log(envResult.data);
       
        if(envResult.data === 'Error'){
            setAliasError(true);
            setAuthInProgress(false);
        } else {
            setAliasError(false);
            const scopes = 'openid email';
            const ssoUrl = `${envResult.data.cognitoDomain}/oauth2/authorize?client_id=${envResult.data.appClientId}&response_type=code&scope=openid&redirect_uri=${envResult.data.redirectUri}&state=${envData}&identity_provider=COGNITO`;
            window.location.href = ssoUrl;
        }
    };
    
    const signOut = async () => {
        
        const logoutUrl = `${serverState.cognitoDomain}/logout?client_id=${serverState.appClientId}&logout_uri=${window.location}`;
        window.location.href = logoutUrl;

       
    };
  
    //This one runs if there is an auth code from cognito
    useEffect(() => {
        let searchParams = new URLSearchParams(window.location.search);
        let code = searchParams.get('code');
        let base64EncodedString= searchParams.get('state');
        let loginComplete= searchParams.get('loginComplete');
        if (code && base64EncodedString && loginComplete !== 'true') {
            console.log("found code and state");
            console.log(searchParams);
            
            // Step 1: Decode base64-encoded string to byte array
            const byteArray = new Uint8Array(atob(base64EncodedString).split('').map(char => char.charCodeAt(0)));
            
            // Step 2: Convert byte array to string
            const decodedString = new TextDecoder().decode(byteArray);
            
            // Step 3: Parse string back into an object
            const codeState = JSON.parse(decodedString);
            console.log("State", codeState);
            searchParams.delete('code');
            searchParams.delete('state');
            searchParams.append('loginComplete', 'true');
            searchParams.append('GUID', codeState.boardGUID);
            searchParams.append('alias', codeState.alias);
            window.history.replaceState({}, document.title, `${window.location.pathname}?${searchParams}`);
            fetchToken(code, codeState.boardGUID, codeState.cognitoDomain, codeState.appClientId, codeState.appClientSecret, codeState.redirectUri, codeState.connectionUrl, codeState.region, codeState.alias)
            .then(() => {
            // Set up the interval to refresh access token
            const intervalId = setInterval(() => {
                console.log('This is the refresh access token loop executing');
                refreshAccessToken(codeState);
            }, 3600000); // 3600000ms = 1 hour

            // Clear the interval when the component is unmounted or dependencies change
            return () => clearInterval(intervalId);
        });
        } 
    }, [fetchToken]);
    
    
    
    //automatically call the sign - in function if alias is a search param but NOT if code/state are present because this is a redirect from cognito
    useEffect(() => {
        const searchParams = new URLSearchParams(window.location.search);
        const alias = searchParams.get('alias');
        const code = searchParams.get('code');
        const state = searchParams.get('state');
        const loginComplete = searchParams.get('loginComplete');
    
        // Call signIn only if alias is present, not null, and neither code nor state params are present
        if (alias !== null && alias.trim() !== '' && code === null && state === null && loginComplete === null && isAuthenticated === false ) {
            signIn(alias);
        }
    }, [isAuthenticated]);
    
    useEffect(() => {
        const searchParams = new URLSearchParams(window.location.search);
        // Call signIn only if alias is present, not null, and neither code nor state params are present
        if (isAuthenticated === true) {
            searchParams.delete('loginComplete');
            window.history.replaceState({}, document.title, `${window.location.pathname}?${searchParams}`);
        }
    }, [isAuthenticated]);
    
    
    
    
    useEffect(() => {
    
        if (isAuthenticated && serverState.identityPoolId && idToken && accessToken) {
            console.log('Auth DB client intiialize');
            const COGNITO_ID = `cognito-idp.${serverState.region}.amazonaws.com/${serverState.userPoolId}`;
            const loginData = {[COGNITO_ID]: idToken};
              const client = new DynamoDBClient({
                region: serverState.region,
                credentials: fromCognitoIdentityPool({
                    clientConfig: { region: serverState.region }, // Configure the underlying CognitoIdentityClient.
                    identityPoolId: serverState.identityPoolId,
                    logins: loginData
                  })
              });
            setDynamoClient(client);
            
        }
        
    }, [isAuthenticated, serverState, idToken, expiresIn, refreshToken, accessToken]);
    
    const refreshAccessToken = async (codeState) => {
            console.log('refresh token loop code state', codeState);
            const tokens = JSON.parse(localStorage.getItem(codeState.alias));
            const refresh_token = tokens.refresh_token;
            console.log('AUTH: Refreshing Token');
            const base64EncodedCredentials = btoa(`${codeState.appClientId}:${codeState.appClientSecret}`);
            const config = {
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                    Authorization: `Basic ${base64EncodedCredentials}`,
                },
        };
        
        const requestData = new URLSearchParams();
            requestData.append('grant_type', 'refresh_token');
            requestData.append('refresh_token', refresh_token);
        
            try {
                const response = await axios.post(`${codeState.cognitoDomain}/oauth2/token`, requestData, config);
                const data = response.data;
                console.log('Auth refresh data: ', data);
                setAccessToken(data.access_token);
                setExpiresIn(data.expires_in);
                console.log('AUTH Expires In: ', data.expires_in);
                // setTokenType(data.token_type);
            } catch (error) {
                console.log(error);
            }
        };
    
    if(isAuthenticated && dynamoClient) {
            return (
                    <Wallbaord
                        board={serverState.boardGUID}
                        setBoard={setBoard}
                        accessToken={accessToken}
                        idToken={idToken}
                        userPoolId={serverState.userPoolId}
                        identityPoolId={serverState.identityPoolId}
                        region={serverState.region}
                        client={dynamoClient}
                        definitionTable={serverState.definitionTable}
                        callbackTable={serverState.callbackTable}
                        currentTable={serverState.currentTable}
                        historicTable={serverState.historicTable}
                        historicTableV2={serverState.historicTableV2}
                        ccxCurrentTable={serverState.ccxCurrentTable}
                        ccxHistoricTable={serverState.ccxHistoricTable}
                        signOut={signOut}
                        refreshAccessToken={refreshAccessToken}
                        symbeeStats={serverState.featureFlags.symbeeStats}
                    /> 
            );
    } else{
        return(
            <LoginPage signIn={signIn} authInProgress={authInProgress} aliasError={aliasError}/>
        );
    }
}

export default App;
