import React, { useEffect, useState } from "react";
import WidgetCard from '../Common/WidgetCard';
import { BatchGetItemCommand, QueryCommand } from '@aws-sdk/client-dynamodb';
import { unmarshall } from '@aws-sdk/util-dynamodb';
import { Text } from '@chakra-ui/react';






function Wallboard({ board, setBoard, accessToken, idToken, region, client, definitionTable, currentTable, historicTable, callbackTable, signOut, refreshAccessToken, ccxCurrentTable, ccxHistoricTable, historicTableV2, symbeeStats }) {
    const [boardData, setBoardData] = useState(null);
    const [currentMetrics, setCurrentMetrics] = useState(null);
    const [historicMetrics, setHistoricMetrics] = useState(null);
    const [historicMetricsV2, setHistoricMetricsV2] = useState(null);
    const [callbackMetrics, setCallbackMetrics] = useState(null);
    const [ccxCurrentMetrics, setCcxCurrentMetrics] = useState([]);
    const [ccxHistoricMetrics, setCcxHistoricMetrics] = useState([]);
    /// extra pience of state to manage the combination of symbeeStats being a boolean and the ccxQueueFetch
    const [ccxCurrentReady, setCcxCurrentReady] = useState(false);
    const [ccxHistoricReady, setCcxHistoricReady] = useState(false);
    
    //one time fetch the board definition
    useEffect(() => {
        console.log('About to fetch board definition for: ', board);
        const fetchData = async () => {
          const command = new QueryCommand({
            TableName: definitionTable,
            KeyConditionExpression: '#pk = :pk',
            ExpressionAttributeNames: { '#pk': 'id' },
            ExpressionAttributeValues: { ':pk': { S: board } },
          });
        
          const response = await client.send(command);
          setBoardData(response.Items.map(item => unmarshall(item))[0]);
        };
        
        fetchData();
    }, [client, board, definitionTable]);
    
    ///perpetually fetch Current stats
    useEffect(() => {
        if(boardData && currentTable && boardData?.widgets) {
            let currentIntervalId;
            const fetchData = async () => {
                try {
                let uncleanedList = [];
                for (var i=0; i< boardData.widgets.length; i++) {
                    for(var j = 0; j < boardData.widgets[i].arn.length; j++){
                        uncleanedList.push(boardData.widgets[i].arn[j]);
                    }
                }
                const itemList = [...new Set(uncleanedList)];
                const command = new BatchGetItemCommand({
                    RequestItems: {
                      [currentTable]: {
                        Keys: itemList.map(id => ({
                            queueId: { S: id } 
                        }))
                      }
                    }
                });
                const response = await client.send(command);
                setCurrentMetrics(response.Responses[currentTable].map(queue => unmarshall(queue)));
                // console.log('Real Time data fetch');
                } 
                catch (error) {
                    console.error('Error fetching data:', error);
                    // refreshAccessToken();
                }
            };
            
            fetchData();
            
            currentIntervalId = setInterval(fetchData, 5000);
            
            return () => {
              clearInterval(currentIntervalId);
            };
        }
    }, [boardData, client, currentTable, refreshAccessToken]);
    
    ///perpetually fetch Historic stats
    useEffect(() => {
      if (boardData && historicTable && boardData?.widgets) {
        let currentIntervalId;
    
        const fetchData = async () => {
          try {
            let uncleanedList = [];
            for (var i = 0; i < boardData.widgets.length; i++) {
              uncleanedList.push(boardData.widgets[i].arn[0]);
            }
            const itemList = [...new Set(uncleanedList)];
            const command = new BatchGetItemCommand({
              RequestItems: {
                [historicTable]: {
                  Keys: itemList.map((id) => ({
                    queueId: { S: id },
                  })),
                },
              },
            });
            const response = await client.send(command);
            setHistoricMetrics(
              response.Responses[historicTable].map((queue) => unmarshall(queue)));
            //   console.log('Historic data fetch');
          } catch (error) {
            console.error('Error fetching data:', error);
            // refreshAccessToken();
          }
        };
    
        fetchData();
    
        currentIntervalId = setInterval(fetchData, 5000);
    
        return () => {
          clearInterval(currentIntervalId);
        };
      }
    }, [boardData, client, historicTable, refreshAccessToken]);
    
    ///perpetually fetch V2  Historic stats
    useEffect(() => {
      if (boardData && historicTableV2 && boardData?.widgets) {
        let currentIntervalId;
    
        const fetchData = async () => {
          try {
            let uncleanedList = [];
            // for (var i = 0; i < boardData.widgets.length; i++) {
            //   uncleanedList.push(boardData.widgets[i].arn[0]);
            // }
              for (var i=0; i< boardData.widgets.length; i++) {
                    for(var j = 0; j < boardData.widgets[i].arn.length; j++){
                        uncleanedList.push(boardData.widgets[i].arn[j]);
                    }
                }
            const itemList = [...new Set(uncleanedList)];
            const command = new BatchGetItemCommand({
              RequestItems: {
                [historicTableV2]: {
                  Keys: itemList.map((id) => ({
                    queueId: { S: id },
                  })),
                },
              },
            });
            const response = await client.send(command);
            console.log(response.Responses[historicTableV2].map((queue) => unmarshall(queue)))
            setHistoricMetricsV2(
              response.Responses[historicTableV2].map((queue) => unmarshall(queue)));
            //   console.log('Historic data fetch');
          } catch (error) {
            console.error('Error fetching data:', error);
            refreshAccessToken();
          }
        };
    
        fetchData();
    
        currentIntervalId = setInterval(fetchData, 5000);
    
        return () => {
          clearInterval(currentIntervalId);
        };
      }
    }, [boardData, client, historicTableV2, refreshAccessToken]);

    
     ///perpetually fetch callback stats
    useEffect(() => {
        if(boardData && callbackTable && boardData?.widgets) {
            let callbackIntervalId;
            const fetchData = async () => {
                try {
                    let uncleanedList = [];
                    for (var i=0; i< boardData.widgets.length; i++) {
                        for(var j = 0; j < boardData.widgets[i].arn.length; j++){
                            uncleanedList.push(boardData.widgets[i].arn[j]);
                        }
                    }
                    const itemList = [...new Set(uncleanedList)];
                    const command = new BatchGetItemCommand({
                        RequestItems: {
                          [callbackTable]: {
                            Keys: itemList.map(id => ({
                                queueId: { S: id }
                            }))
                          }
                        }
                    });
                    const response = await client.send(command);
                    setCallbackMetrics(response.Responses[callbackTable].map(queue => unmarshall(queue)));
                    // console.log('Callback data fetch');
                } catch (error) {
                    console.error('Error fetching data:', error);
                    // refreshAccessToken();
                }
            };
            
            fetchData();
            
            callbackIntervalId = setInterval(fetchData, 5000);
            
            return () => {
              clearInterval(callbackIntervalId);
            };
        }
    }, [boardData, client, callbackTable, refreshAccessToken]);
    
     ///perpetually fetch CCX Current Stats
    useEffect(() => {
        if(symbeeStats && boardData && ccxCurrentTable && boardData?.widgets) {
            let callbackIntervalId;
            const fetchData = async () => {
                try {
                    let uncleanedList = [];
                    for (var i=0; i< boardData.widgets.length; i++) {
                        for(var j = 0; j < boardData.widgets[i].arn.length; j++){
                            uncleanedList.push(boardData.widgets[i].arn[j]);
                        }
                    }
                    const itemList = [...new Set(uncleanedList)];
                    const command = new BatchGetItemCommand({
                        RequestItems: {
                          [ccxCurrentTable]: {
                            Keys: itemList.map(id => ({
                                queueId: { S: id }
                            }))
                          }
                        }
                    });
                    const response = await client.send(command);

                    setCcxCurrentMetrics(response.Responses[ccxCurrentTable].map(queue => unmarshall(queue)));
                    setCcxCurrentReady(true);
                    // console.log('CCX Current data fetch');
                } catch (error) {
                    console.log('Error fetching data:', error);
                    // refreshAccessToken();
                }
            };
            
            fetchData();
            
            callbackIntervalId = setInterval(fetchData, 5000);
            
            return () => {
              clearInterval(callbackIntervalId);
            };
        }
        else {
            setCcxCurrentReady(true);
        }
    }, [boardData, client, ccxCurrentTable, refreshAccessToken, symbeeStats]);
    
    
    ///perpetually fetch CCX Historic Stats
 useEffect(() => {
        
        if(symbeeStats && boardData && ccxHistoricTable && boardData?.widgets) {
            let callbackIntervalId;
            const fetchData = async () => {
                try {
                    let uncleanedList = [];
                    for (var i=0; i< boardData.widgets.length; i++) {
                        for(var j = 0; j < boardData.widgets[i].arn.length; j++){
                            uncleanedList.push(boardData.widgets[i].arn[j]);
                        }
                    }
                    const itemList = [...new Set(uncleanedList)];
                    const command = new BatchGetItemCommand({
                        RequestItems: {
                          [ccxHistoricTable]: {
                            Keys: itemList.map(id => ({
                                queueId: { S: id }
                            }))
                          }
                        }
                    });
                    const response = await client.send(command);
                    setCcxHistoricMetrics(response.Responses[ccxHistoricTable].map(queue => unmarshall(queue)));
                    setCcxHistoricReady(true);
                    // console.log('CCX Historic data fetch');
                } catch (error) {
                    console.log('Error fetching data:', error);
                    // refreshAccessToken();
                }
            };
            
            fetchData();
            
            callbackIntervalId = setInterval(fetchData, 5000);
            
            return () => {
              clearInterval(callbackIntervalId);
            };
        }
        else {
            setCcxHistoricReady(true);
        }
    }, [boardData, client, ccxHistoricTable, refreshAccessToken, symbeeStats]);   
    
 
 const getWidth = () => {
   if(boardData.widgets.length >= 13) { return '17%' }
   else { return '22%'}
 };
 
 
 
 const getHeight = () => {
   if(boardData.widgets.length <= 4) { return '40%' }
   else if (boardData.widgets.length <= 8) { return '30%' }
   else { return '25%'}
 };
 
 
 
 const getStatistic = (stat, queueId, type) => {
    if(queueId.length === 1){
        if(type === 'current') {
            for (var i = 0; i < currentMetrics.length; i++) {
                 
                if(currentMetrics[i].queueId === queueId[0]) {
                    return currentMetrics[i][stat];
                }
            }
        } 
        else if(type === 'historical') {
            for (var t = 0; t < historicMetrics.length; t++) {
                 
                if(historicMetrics[t].queueId === queueId[0]) {
                     return historicMetrics[t][stat];
                 }
             }
         }
         else if(type === 'historicalV2') {
            for (var k = 0; k < historicMetricsV2.length; k++) {
                 
                if(historicMetricsV2[k].queueId === queueId[0]) {
                     return historicMetricsV2[k][stat];
                 }
             }
         }
         else if(type === 'callback') {
            for (var z = 0; z < callbackMetrics.length; z++) {
                 
                if(callbackMetrics[z].queueId === queueId[0]) {
                    return callbackMetrics[z][stat];
                }
            }
        }
        else if(type === 'CCX current') {
            for (var j = 0; j < ccxCurrentMetrics.length; j++) {
                if(ccxCurrentMetrics[j].queueId === queueId[0]) {
                    return ccxCurrentMetrics[j][stat];
                    
                }
            }
        }
        else if(type === 'CCX historical') {
            
            for (var r = 0; r < ccxHistoricMetrics.length; r++) {
                if(ccxHistoricMetrics[r].queueId === queueId[0]) {
                    return ccxHistoricMetrics[r][stat];
    
                }
                
               
            }
            // queue not found in CCX table
            return 0;
        }
    } 
    else if (queueId.length > 1) {
        if(stat === 'CALLBACKS_WAITING') {
            let combinedStat = 0;
            for (var e = 0; e < queueId.length; e++){
                for (var f = 0; f < callbackMetrics.length; f++) {
                    
                    if(callbackMetrics[f].queueId === queueId[e]) {
                        combinedStat += callbackMetrics[f][stat];
                    }
                }
            }
            return combinedStat; 
        }
        else if(stat === 'CONTACTS_HANDLED') {
            let combinedStat = 0;
            for (var g = 0; g < queueId.length; g++){
                for (var h = 0; h < historicMetricsV2.length; h++) {
                    
                    if(historicMetricsV2[h].queueId === queueId[g]) {
                        combinedStat += historicMetricsV2[h][stat];
                    }
                }
            }
            return combinedStat; 
        }
        else if(stat === 'CONTACTS_ABANDONED') {
            let combinedStat = 0;
            for (var g = 0; g < queueId.length; g++){
                for (var h = 0; h < historicMetricsV2.length; h++) {
                    
                    if(historicMetricsV2[h].queueId === queueId[g]) {
                        combinedStat += historicMetricsV2[h][stat];
                    }
                }
            }
            return combinedStat; 
        }
        else if(stat === 'CONTACTS_QUEUED') {
            let combinedStat = 0;
            for (var g = 0; g < queueId.length; g++){
                for (var h = 0; h < historicMetricsV2.length; h++) {
                    
                    if(historicMetricsV2[h].queueId === queueId[g]) {
                        combinedStat += historicMetricsV2[h][stat];
                    }
                }
            }
            return combinedStat; 
        }
        else if(stat === 'CONTACTS_IN_QUEUE') {
            let combinedStat = 0;
            for (var g = 0; g < queueId.length; g++){
                for (var h = 0; h < currentMetrics.length; h++) {
                    
                    if(currentMetrics[h].queueId === queueId[g]) {
                        combinedStat += currentMetrics[h][stat];
                    }
                }
            }
            return combinedStat; 
        }
        else if(stat === 'OLDEST_CONTACT_AGE') {
            let combinedStat = [];
            for (var k = 0; k < queueId.length; k++){
                for (var l = 0; l < currentMetrics.length; l++) {
                    
                    if(currentMetrics[l].queueId === queueId[k]) {
                        combinedStat.push(currentMetrics[l][stat]);
                    }
                }
            }
            return  Math.max(...combinedStat); 
        }
        else if(stat === 'OLDEST_CALLBACK') {
            let combinedStat = [];
            for (var m = 0; m < queueId.length; m++){
                for (var n = 0; n < callbackMetrics.length; n++) {
                    
                    if(callbackMetrics[n].queueId === queueId[m]) {
                        combinedStat.push(callbackMetrics[n][stat]);
                    }
                }
            }
            return  Math.min(...combinedStat); 
        }
        else if(stat === 'CONTACTS_IN_QUEUE') {
            let combinedStat = 0;
            for (var g = 0; g < queueId.length; g++){
                for (var h = 0; h < currentMetrics.length; h++) {
                    
                    if(currentMetrics[h].queueId === queueId[g]) {
                        combinedStat += currentMetrics[h][stat];
                    }
                }
            }
            return combinedStat; 
        }
        else if(stat === 'oldestItem') {
            let combinedStat = [];
            for (var k = 0; k < queueId.length; k++){
                for (var l = 0; l < ccxCurrentMetrics.length; l++) {
                    
                    if(ccxCurrentMetrics[l].queueId === queueId[k]) {
                        combinedStat.push(ccxCurrentMetrics[l][stat]);
                    }
                }
            }
            return  Math.max(...combinedStat); 
        }
        else if(stat === 'itemsInQueue') {
            let combinedStat = [];
            for (var k = 0; k < queueId.length; k++){
                for (var l = 0; l < ccxCurrentMetrics.length; l++) {
                    
                    if(ccxCurrentMetrics[l].queueId === queueId[k]) {
                        combinedStat += ccxCurrentMetrics[k][stat];
                    }
                }
            }
            return  Math.max(...combinedStat); 
        }
    }
     
     return 'Error';
     
 };
 
 
 if(boardData && currentMetrics && callbackMetrics && historicMetrics && historicMetricsV2 && ccxCurrentReady && ccxHistoricReady) {
  return (
    <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'start', alignItems:'end', height: '100vh', width: '100vw', background: boardData.theme.bgColor}}>
        <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between', width: '100%', height: '10%'}}>
            <span style={{minWidth: '10%', maxWidth: '30%', marginLeft: '1em', marginTop: '0.25em'}}>
               {boardData.imgUrl ? <img alt='logo' src={boardData.imgUrl} style={{height: '100%', }} /> : null }
            </span>
            <span style={{minWidth: '60%', maxWidth:'85%'}}>
                <Text color={boardData.theme.fontColor} fontSize='5xl'> {boardData.name}</Text>
            </span>
        </div>
        <div style={{display: 'flex', flexDirection: 'row', flexFlow: 'wrap', alignItems: 'center', justifyContent: 'space-evenly', width: '100%', height: '100%', background: boardData.theme.bgColor}}>
            <>
                {boardData.widgets.map((widget, itx) => {
                  return (
                    <WidgetCard key={`widgetcard-key-${itx}`} name={widget.name} threshold={widget.thresholds} statistic={widget.statistic[0]} cardWidth={getWidth()} cardHeight={getHeight()} theme={boardData.theme} value={getStatistic(widget.statistic[0], widget.arn, widget.type)} />
                  );
                })}
            </>
        </div>
    </div>
  );
 }
 else if(boardData?.widgets.length > 1) {
    return (
        
        <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'start', alignItems:'end', height: '100vh', width: '100vw'}}>
            <div style={{display: 'flex', flexDirection: 'row', flexFlow: 'wrap', alignItems: 'center', justifyContent: 'space-evenly', width: '100%', height: '100%'}}>
                <Text fontSize='3xl'> Fetching Wallboard...</Text>
            </div>
        </div>
    );
 }
 else {
     return (
        
        <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'start', alignItems:'end', height: '100vh', width: '100vw'}}>
            <div style={{display: 'flex', flexDirection: 'row', flexFlow: 'wrap', alignItems: 'center', justifyContent: 'space-evenly', width: '100%', height: '100%'}}>
                <Text fontSize='3xl'> No widgets configured</Text>
            </div>
        </div>
    );
     
 }
}

export default Wallboard;


